import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
// import App from './App';
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./redux/store";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import Navbar from "./components/NavBar/Navbar.jsx";
import CalenderInput from "./components/Calender/AddCalender/CalenderInput.jsx";
import EditPage from "./components/EditPage/EditPage.jsx";
import Registration from "./components/Registration/Registration.jsx";
import UserRefistrationForm from "./components/Registration/UserRefistrationForm.jsx";
import EditRegistration from "./components/Registration/EditRegistration.jsx";
import Downloads from "./components/download/Download.jsx";
import { Toaster } from "react-hot-toast";
import { AuthContextProvider } from "./context/authContextProvider.js";
import PrivateRoute from "./components/PrivateRoutes.jsx";
import Signup from "./components/aashviPT/Registration/Registration.jsx"
import Login from "./components/aashviPT/Login/Login.jsx";
import DisplayCalender from "./components/Calender/DisplayCalenders/DisplayCalender.jsx";
// import UserFormDownload from "./components/UsersRegistration/UserFormDownload.jsx";
import UserFormYear from "./components/UsersRegistration/UserFormYear.jsx";
// All components Imports

const root = ReactDOM.createRoot(document.getElementById("root"));

const route = createBrowserRouter(
  createRoutesFromElements(
    <>
    {/* unprotected Routes */}
    <Route path="/" element={<Navbar />}>
      <Route path="registration" element={<Registration />} />
      <Route path="userregistration" element={<UserRefistrationForm />} />
      <Route path="editregistration" element={<EditRegistration />} />
      <Route path="APTlogin" element={<Login />} />
      <Route path="download" element={<Downloads />} />
    </Route>
    <Route path="/APTlabUserPannel" element={<PrivateRoute/>}>
      
      <Route path="download" element={<Downloads />} />
      <Route path="signup" element={<Signup />} />
      <Route path="calender/" element={<CalenderInput />} />
      <Route path="edit" element={<EditPage />} />
      <Route path="userDownload" element={<UserFormYear />} />
      <Route path="edit/calender/" element={<CalenderInput />} />
      <Route path="update" element={<EditPage/>}/>
      <Route path="display" element={<DisplayCalender />} />
    </Route>
    </>
  )
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <AuthContextProvider>
        <Toaster />
        <RouterProvider router={route} />
      </AuthContextProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

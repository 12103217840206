import React from 'react'
import "./privateNav.css"
import { Outlet,Link } from 'react-router-dom'

function PrivateNav() {
  return (
    <main  className='h-screen Nav-main'>
      <nav  className='flex justify-center mt-6'>
      <div className='bg-[#171717] fixed nav-shadow p-2 w-9/12  h-14 rounded-full'>

        <ul className='flex text-white space-x-3 justify-center gap-10 m-1 font-bold text-lg'>
          {/* <Link to={'/calender/#1'}><li>Calender</li></Link> */}
          <Link to={'/APTlabUserPannel/display'}><li>Calender</li></Link>
          {/* <Link to={'/userregistration'}><li>Registration</li></Link> */}
          <Link to={'/APTlabUserPannel/userDownload'}><li>Registerd Labs</li></Link>
        </ul>
      </div>
      </nav>
      <section  className='mt-20'>

      <img src="/APT.png" className='backgroung-image' alt="some" />
      <Outlet/>
      </section>
    </main>
  )
}

export default PrivateNav

import React, {  useState } from 'react'
import './registration.css'
import {  Navigate } from 'react-router-dom'
import { useDispatch,useSelector } from 'react-redux';
import { useForm } from 'react-hook-form'
import { setLabName,setLabNabl,setLabGstin,setLabEmail,setLabContactNo,setLabContact,setLabAddress} from '../../redux/userSclice.js';

function UserRefistrationForm() {
    // const userData = useSelector(state=>state.user)
    


    const Calender = localStorage.getItem("userData");
  const parsedCalender = Calender ? JSON.parse(Calender) : {}; 
  
  console.log("parseCal", parsedCalender)
  const { register, handleSubmit,formState:{errors} } = useForm({
    defaultValues:parsedCalender
  });
  const [redirectToEdit,setRedirectToEdit] = useState(false);
  const [redirectToRound,setRedirectToRound] = useState(false);
  const user = useSelector((state) => state.user.round);
const dispatch = useDispatch();
  const onSubmit = async (data) => {
    try {
        dispatch(setLabName(data.labName))
        dispatch(setLabNabl(data.labNabl))
        dispatch(setLabGstin(data.labGstin))
        dispatch(setLabEmail(data.labEmail))
        dispatch(setLabContactNo(data.labContactNo))
        dispatch(setLabContact(data.labContact))
        dispatch(setLabAddress(data.labAddress))
        
        
        if(user.length === 0){
            setRedirectToRound(true);
        }else{
            setRedirectToEdit(true);
        }
        
    } catch (error) {
        console.log("error",error);
    }
  };



  return (
    <div className='flex flex-col items-center  h-[92%] registration-background'>
        <img src="/APT.png" alt="APT" className='img-user' />
      <form onSubmit={handleSubmit(onSubmit)} className='grid grid-cols-2 gap-4 justify-center w-11/12'>
      <div className='flex flex-col'>
      <label htmlFor="labName" className=' font-bold mb-2'>Name of the Laboratory:</label>
        <input {...register("labName",{
            
            required:"Mandatory field",
            maxLength:{
                value:150,
                message:"Lab name can have maximum 100 charactors"
            },
            minLength:{
                value:1,
                message:"Lab name can have minimum 10 charactors"
            },
        })} type="text" name='labName' placeholder=' Name of the Laboratory' className='border-2 border-black rounded-md p-1 mb-4 '/>
        {errors.labName && <p className='text-red-500 font-bold'>{errors.labName.message}</p>}
        <label htmlFor="labAddress" className=' font-bold mb-2'>Complete Address of the Laboratory with pin code:</label>
        <textarea {...register("labAddress",{
            required:"Mandatory field",
           
            minLength:{
                value:15,
                message:"Lab adress can have minimum 15 charactors"
            },

        })} type="text" name='labAddress' placeholder=' Complete Address of the Laboratory with pin code:' className='border-2 max-h-20 min-h-9 border-black rounded-md p-1 mb-4 '/>
        {errors.labAddress && <p className='text-red-500 font-bold'>{errors.labAddress.message}</p>}
        <label htmlFor="labContact" className=' font-bold mb-2'>Contact Person Name:</label>
        <input {...register("labContact",{
            required:"Mandatory field",
            maxLength:{
                value:150,
                message:"Contact Person Name can have maximum 150 charactors"
            },
            minLength:{
                value:1,
                message:"Contact Person Name can have minimum 5 charactors"
            },
            
        })} type="text" name='labContact' placeholder='Contact Person Name:' className='border-2 border-black rounded-md p-1 mb-4 '/>
        {errors.labContact && <p className='text-red-500 font-bold'>{errors.labContact.message}</p>}
        <label htmlFor="labContactNo" className=' font-bold mb-2'>Tel/Mobile Nos:</label>
        <input {...register("labContactNo",{
            required:"Mandatory field",
           
            minLength:{
                value:8,
                message:"Contact number should be atleast 8 charactors only"
            },
        })} type="text" name='labContactNo' placeholder='Tel/Mobile Nos:' className='border-2 border-black rounded-md p-1 mb-4 '/>
        {errors.labContactNo && <p className='text-red-500 font-bold'>{errors.labContactNo.message}</p>}

      </div>
      <div className='flex flex-col'>

        <label htmlFor="labEmail" className=' font-bold mb-2'>E-mail ID:</label>
        <input {...register("labEmail",{
            required:"Mandatory field",
            maxLength:{
                value:150,
                message:"Email can have maximum 150 charactors"
            },
            minLength:{
                value:5,
                message:"Email can have minimum 5 charactors"
            },
            // pattern:{
            //     value:/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            //     message:"Invalid Email"
            // }
        })} type="text" name='labEmail' placeholder=' E-mail ID:' className='border-2 border-black rounded-md p-1 mb-4 '/>
        {errors.labEmail && <p className='text-red-500 font-bold'>{errors.labEmail.message}</p>}

        <label htmlFor="labGstin" className=' font-bold mb-2'>Lab’s GSTIN: </label>
        <input {...register("labGstin",{
            required:"Mandatory field",
            maxLength:{
                value:15,
                message:"GSTIN can not have more then 15 Charactors "
            },
            minLength:{
                value:11,
                message:"If unregistered under GST mention “Unregistered”"
            },
            // pattern:{
            //     value:/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
            //     message:"Invalid GSTIN"
            // }
        })} type="text" name='labGstin' placeholder='Lab’s GSTIN: ( If unregistered under GST mention “Unregistered”)' className='border-2 border-black rounded-md p-1 mb-4 '/>
        {errors.labGstin && <p className='text-red-500 font-bold'>{errors.labGstin.message}</p>}

        <label htmlFor="labNabl" className=' font-bold mb-2'>NABL Certificate no:</label>
        <input {...register("labNabl",{
            required:"Mandatory field",
            maxLength:{
                value:10,
                message:"NABL Certificate no can have maximum 10 charactors"
            },
            minLength:{
                value:2,
                message:"NABL Certificate no can have minimum 2 charactors"
            },
            // pattern:{
            //     value:/^[A-Z][A-Z]-\d{4,6}$/,
            //     message:"Invalid NABL Certificate no"
            // }
        })} type="text" name='labNabl' placeholder='NABL Certificate no:  (‘NA’- if not applicable )' className='border-2 border-black rounded-md p-1 mb-4 '/>
        {errors.labNabl && <p className='text-red-500 font-bold'>{errors.labNabl.message}</p>}

        <button type='submit' className='bg-blue-500 text-white px-4 py-2 rounded-md mt-7 '>Submit</button>
      </div>

      </form>
      {redirectToRound && <Navigate to="/registration" />}
      {redirectToEdit && <Navigate to="/editregistration" />}
    </div>
  )
}

export default UserRefistrationForm

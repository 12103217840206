import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
    name: "labUser",
    initialState: {
        year:'',
        _id:"",
        labName: "",
        labAddress: "",
        labContact: "",
        labContactNo: "",
        labEmail: "",
        labGstin: "",
        labNabl: "",
        paymentMethod: "",
        createdAt:"",
        round:[]
    },
    reducers: {
        setYear: (state, action) => {
            state.year = action.payload;
        },
        setLabName: (state, action) => {
            state.labName = action.payload;
        },
        setLabAddress: (state, action) => {
            state.labAddress = action.payload;
        },
        setLabContact: (state, action) => {
            state.labContact = action.payload;
        },
        setLabContactNo: (state, action) => {
            state.labContactNo = action.payload;
        },
        setLabEmail: (state, action) => {
            state.labEmail = action.payload;
        },
        setLabGstin: (state, action) => {
            state.labGstin = action.payload;
        },
        setLabNabl: (state, action) => {
            state.labNabl = action.payload;
        },
        setRound: (state, action) => {
            state.round = action.payload;
        },
        setPaymentMethod: (state, action) => {
            state.paymentMethod = action.payload;
        },
        createdAt: (state, action) => {
            state.createdAt = action.payload;
        },
        set_id: (state, action) => {
            state._id = action.payload;
        },
        resetUser: (state) => {
            state._id = ""
            state.year='';
            state.labName = "";
            state.labAddress = "";
            state.labContact = "";
            state.labContactNo = "";
            state.labEmail = "";
            state.labGstin = "";
            state.labNabl = "";
            state.paymentMethod = "";
            state.createdAt = "";
            state.round = [];
        },
    },
});

export default userSlice.reducer;
export const {set_id,createdAt, setLabName, setLabAddress,setYear, setLabContact, setLabContactNo, setLabEmail, setLabGstin, setLabNabl, setRound, resetUser,setPaymentMethod } = userSlice.actions;
import React, { Suspense, useEffect, useState } from 'react';
import axios from '../../api/axiosPublic.js';
import { useLocation } from 'react-router-dom';
import Loader from "../Loader.jsx";

// Lazy load your components
const RegistrationForm = React.lazy(() => import('./RegistrationForm.jsx'));
const UserFormDownload = React.lazy(() => import('../UsersRegistration/UserFormDownload.jsx'));

function Registration() {
  const location = useLocation();
  const [years, setYears] = useState([]);
  const [calender, setCalender] = useState(null);
  const [loading, setLoading] = useState(true); // Track loading state for async data
  const userdata = localStorage.getItem("userData");

  let userYear;
  if (!userdata) {
    userYear = new Date().getFullYear();
    console.log(userYear);
  } else {
    userYear = JSON.parse(userdata).year === '' ? new Date().getFullYear() : JSON.parse(userdata).year;
    console.log("user data .year ", userYear);
  }

  const [year, setYear] = useState(userYear);
  const [currentPath, setCurrentPath] = useState(location.pathname);

  useEffect(() => {
    setCurrentPath(location.pathname);

    const fetchYears = async () => {
      try {
        const res = await axios.get('/years');
        let tempYear = res.data.data;
        if (userYear !== new Date().getFullYear()) {
          let index = tempYear.indexOf(parseInt(userYear));
          if (index !== -1) {
            let swap = tempYear[0];
            tempYear[0] = userYear;
            tempYear[index] = swap;
          }
        }
        setYears(tempYear);
      } catch (error) {
        handleError(error, 'Fetching years');
        setYears([]);
      }
    };

    const fetchCalendar = async () => {
      try {
        const res = await axios.post('/registration', { year: year });
        setCalender(res.data.data);
      } catch (error) {
        handleError(error, 'Fetching calendar');
        setCalender(null);
      } finally {
        setLoading(false); // Stop loading after fetching
      }
    };

    fetchYears();
    fetchCalendar();
  }, [year, location.pathname, userYear]);

  const handleError = (error, context) => {
    if (error.response) {
      console.error(`${context} failed:`, error.response.status, error.response.data);
      alert(`Error: ${error.response.status} - ${error.response.data.message || error.message}`);
    } else if (error.request) {
      console.error(`${context} failed: No response received`, error.request);
      alert('No response from the server. Please check your network connection.');
    } else {
      console.error(`${context} failed:`, error.message);
      alert('An unexpected error occurred. Please try again.');
    }
  };

  return (
    <div className=''>
      <div className='flex justify-center'>
        <strong className='font-bold text-xl'>
          {currentPath === "/registration" ? "Calendar Of Year" : "User Register In Year"}
        </strong>
        <select
          name="year"
          className='rounded-md w-20 border-2 border-black ml-2'
          onChange={(e) => setYear(e.target.value)}
        >
          {
            years.map((year, index) => (
              <option key={index} value={year}>
                {year}
              </option>
            ))
          }
        </select>
      </div>

      {loading ? (
        <Loader /> // Show loader while data is being fetched
      ) : (
        <Suspense fallback={<Loader />}>
          {currentPath === "/registration"
            ? calender
              ? <RegistrationForm Calender={calender} year={year} />
              : null
            : years.length !== 0
              ? <UserFormDownload year={year} />
              : null}
        </Suspense>
      )}
    </div>
  );
}

export default Registration;



import * as XLSX from 'xlsx';





export const writeToJsonExcel = (data) => {
  // Create a map to store roundNames and corresponding lab details
  const roundMap = {};
  data.forEach(lab => {
    lab.round.forEach(round => {
      if (!roundMap[round.roundName]) {
        roundMap[round.roundName] = [];
      }
      roundMap[round.roundName].push({
        labID :lab._id,
        labName: lab.labName,
        labAddress: lab.labAddress,
        labEmail: lab.labEmail,
        labContact: lab.labContact,
        labContactNo:lab.labContactNo,
        labGstin:lab.labGstin,
        labNabl:lab.labNabl,
        paymentMethod:lab.paymentMethod,
        createdAt:lab.createdAt,
        roundPrice:round.roundPrice,
        roundMatrix:round.roundMatrix,
        rounds:lab.round
        
      });
    });
  });
console.log(roundMap)
  // Sort the round names
  const sortedRoundNames = Object.keys(roundMap).sort((a, b) => {
    const numA = parseInt(a.split('-')[1], 10);
    const numB = parseInt(b.split('-')[1], 10);
    return numA - numB; // Sort in ascending order
  });
console.log(sortedRoundNames)
  // Create an array from the sorted round names
  const excelData = [];
  sortedRoundNames.forEach(roundName => {
    
    excelData.push([roundName,roundMap[roundName][0].roundMatrix,`${roundMap[roundName][0].roundPrice} + 18% GST`]); // Add round name as a new row
    excelData.push([ "Registration ID","Lab Name & Address","Contact Person & Tel Nos","E-mail","PT Registration form Received Date","Enrolled for PT Rounds as per PT registration form only","Payment Details","GST Num","NABL Certificate Num:"]); // Add round name as a new row
    console.log("roundMap",roundMap)
    roundMap[roundName].forEach(lab => {
      console.log("lab",lab)
      let labRound =[]
      lab.rounds.forEach(item =>{
        labRound.push(item.roundName)
      })
      excelData.push([ lab.labID,`${lab.labName}, ${lab.labAddress}`,`${lab.labContact}, ${lab.labContactNo}`, lab.labEmail,(new Date(lab.createdAt)).toLocaleDateString("en-GB").replace(/\//g, "-"),`${labRound}`,lab.paymentMethod,lab.labGstin,lab.labNabl,]); // Add lab details under the corresponding round
    });
    excelData.push(['']); // Add round name as a new row
    excelData.push(['']); // Add round name as a new row
  });

  const ws = XLSX.utils.aoa_to_sheet(excelData);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  XLSX.writeFile(wb, 'output.xlsx');
};

